import {useEffect, useState} from "react";

function BadgeCart() {
    const [data,setdata] = useState((localStorage.getItem("cart") ? JSON.parse(localStorage.getItem("cart")!) : [])  as any)

    useEffect(()=>{
        if(localStorage.getItem("cart")){
            if(JSON.parse(localStorage.getItem("cart")!).length > 0){
                setdata(JSON.parse(localStorage.getItem("cart")!))
            }else{
                localStorage.removeItem("cart")
                setdata([])
            }
        }
    },[localStorage.getItem("cart")!])
    return(
       <>
           {
               data.length > 0 &&
               <span className={"justify-content-center align-items-center"} style={{display:"flex",position:"absolute",top:10,left:10,backgroundColor:"rgb(231,33,44)",color:"#fff",width:20,height:20,borderRadius:"100%",fontSize:11}}>
                   {
                       data.length
                   }
               </span>
           }

       </>
    )
}
export default BadgeCart
