import React, {useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/css/App.css';
import {Routes, Route, useNavigate, useNavigation, useLocation} from "react-router-dom";
import Home from "./pages/home";
import Header from "./container/header/header";
import Footer from "./container/footer/footer";
import Contact from "./pages/contact";
import Category from "./pages/category";
import Products from "./pages/products";
import Page from "./pages/page";
import Product from "./pages/product";

function App() {
    const nav = useLocation()
    useEffect(()=>{
        setTimeout(()=>{
            window.scrollTo(0,0)
        },50)
    },[nav.pathname])
  return (
    <div className="App">
        <Header />
        <Routes>
            <Route index path={"*"} element={<Home />} />
            <Route index path={"pages/contact"} element={<Contact />} />
            <Route index path={"collections/all"} element={<Category />} />
            <Route index path={"products/:id"} element={<Products />} />
            <Route index path={"product/:id"} element={<Product />} />
            <Route index path={"page/:id"} element={<Page />} />


        </Routes>
        <Footer />
    </div>
  );
}

export default App;
