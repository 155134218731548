import {Link, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import { PagesData} from "../helper/data";
import {Breadcrumb, Col, Container, Row} from "react-bootstrap";
import ProductBox from "../container/product/product";

function Page() {
    const {id} = useParams()
    const [data,setdata] = useState({} as any)

    useEffect(()=>{
        const index = PagesData.findIndex((v:any)=>{
            return v.title.replaceAll(" ","-").toLowerCase() == id
        });
        if(index >=0){
            setdata(PagesData[index])
        }

    },[id])


    return(
        <Container className={"py-md-4 py-4 mb-md-5 "} fluid={"md"} style={{minHeight:500}}  >
            <Row>
                <Col md={12}>
                    <h1 className={"title-page fw-bolder pt-4"}>{data.title}</h1>
                </Col>
                <Col md={12} style={{fontSize:17,textAlign:"justify"}}>
                    {
                        data.description
                    }
                </Col>
            </Row>
        </Container>

    )
}
export default Page
