import {Col, Container, Image, Row} from "react-bootstrap";
import {ProductCategory} from "../helper/data";
import {Link} from "react-router-dom";

function Category() {
    return(
        <Container className={"py-md-4 py-4 my-md-5 "} fluid={"md"}  >
            <Row>
                <Col md={12}><h1 className={"title-page fw-bolder"}>Category</h1></Col>


                {
                    ProductCategory.map((v:any,i:number)=>{
                        return(
                            <Col md={4} className={"mb-3"} key={i}>
                                <Link to={v.url}>
                                    <div className={"ratio ratio-1x1"}>
                                        <div className={"overflow-hidden"}>
                                            <Image srcSet={v.image} className={"position-absolute top-50 start-50 translate-middle object-fit-cover"} width={"100%"} height={"100%"} style={{objectPosition:"center center"}}  />
                                        </div>
                                    </div>
                                    <p className={"mt-1"}>
                                        {v.title}
                                        <span className={"ms-2"}>
                                            <svg viewBox="0 0 14 10" width={20} fill="none" aria-hidden="true" focusable="false"
                                                 className="icon icon-arrow" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M8.537.808a.5.5 0 01.817-.162l4 4a.5.5 0 010 .708l-4 4a.5.5 0 11-.708-.708L11.793 5.5H1a.5.5 0 010-1h10.793L8.646 1.354a.5.5 0 01-.109-.546z"
                                                fill="currentColor">
                                            </path>
                                        </svg>
                                        </span>
                                    </p>
                                </Link>
                            </Col>
                        )
                    })
                }

            </Row>
        </Container>
    )
}
export default Category
