import {Button} from "react-bootstrap";
import {useState} from "react";
import {ProductsData} from "../../helper/data";
import Swal from 'sweetalert2'

function Addtocart({id="",counter=1,...prop}) {
    const cart = {title: "",id:"",count:1,index: 0,price:0,total: 0 }
    const [data,setdata] = useState((localStorage.getItem("cart") ? JSON.parse(localStorage.getItem("cart")!) : [])  as any)
    const addtocart = () => {
        const index = ProductsData.findIndex((v:any)=>{
            return v.title.replaceAll(" ","-").replaceAll(":","").toLowerCase() == id
        });
        if(index > 0){
            const pro = ProductsData[index];
            let d:any = {}
            if(localStorage.getItem("cart")){
                const save = JSON.parse(localStorage.getItem("cart")!)
                const index2 = save.findIndex((v:any)=>{
                    return v.id == id
                });

                if(index2 >= 0){
                    save[index2].count = save[index2].count + counter
                    save[index2].total = parseFloat(pro.price.replaceAll(",","")) * save[index2].count
                    save[index2].price = parseFloat(pro.price.replaceAll(",",""))
                    setdata(save)
                    localStorage.setItem("cart",JSON.stringify(save))
                }else{
                    d = {title: pro.title,id:id,count:counter,index: index,price:parseFloat(pro.price.replaceAll(",","")),total: parseFloat(pro.price.replaceAll(",","")) * counter }

                    const dd = data.concat(d)
                    setdata(dd)
                    localStorage.setItem("cart",JSON.stringify(dd))
                }
            }else{
                d = {title: pro.title,id:id,count:counter,index: index,price:parseFloat(pro.price.replaceAll(",","")),total: parseFloat(pro.price.replaceAll(",","")) * counter }
                const dd = data.concat(d)
                setdata(dd)
                localStorage.setItem("cart",JSON.stringify(dd))
            }

            Swal.fire({
                position: "top-end",
                icon: "success",
                title: "",
                text:"Item Added To Cart",
                showConfirmButton: false,
                timer: 1500
            });

        }
    }
    return(
        <>
            <Button onClick={()=>{addtocart()}} {...prop}  >Add to cart</Button>
        </>
    )
}
export default Addtocart
