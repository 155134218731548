import {Button, Col, Container, Form, Row} from "react-bootstrap";

function Contact() {
    return(
        <Container className={"py-md-5 py-4 my-md-5"} fluid={"md"} style={{maxWidth:900}}>
            <Row>
                <Col md={12}><h1 className={"title-page fw-bolder"}>Contact</h1></Col>
                <Col md={12}>
                    <Form>
                        <Row>
                            <Col md={6}>
                                <Form.Group controlId={"name"}>
                                    <Form.Control placeholder={"Name *"} type={"text"} name={"name"} required />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId={"email"}>
                                    <Form.Control placeholder={"Email *"} type={"text"} name={"email"} required />
                                </Form.Group>
                            </Col>
                            <Col md={12}>
                                <Form.Group controlId={"phone"}>
                                    <Form.Control placeholder={"Phone Number"} type={"tel"} name={"phone"}  />
                                </Form.Group>
                            </Col>
                            <Col md={12}>
                                <Form.Group controlId={"Content"}>
                                    <Form.Control as={"textarea"} rows={4} placeholder={"Content *"} name={"content"} />
                                </Form.Group>
                            </Col>
                            <Col md={12}>
                                <Button type={"submit"} className={"btn-dark"}>Send</Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </Container>
    )
}
export default Contact
