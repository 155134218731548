import './css.css'
import {Col, Container, Nav, Row, Navbar, NavDropdown, Image} from "react-bootstrap";
import logo from "../../assets/images/logo.svg"
import {Menu} from "../../helper/data";
import {Link} from "react-router-dom";
import BadgeCart from "../cart/badgecart";
function Header() {
    return(
        <div className={" position-relative" + (window.location.pathname == "/" ? " header2" : "")} >
            <header className={"position-sticky top-0 start-0 w-100"} style={{zIndex: 9}}>
                <Container>
                    <Row>
                        <Col md={12} className={"py-2"}>
                            <Navbar collapseOnSelect expand="lg">
                                <Navbar.Brand as={Link} to="./">
                                    <Image srcSet={logo} height={30} alt={""}/>
                                </Navbar.Brand>
                                <Navbar.Toggle className={"border-0 filter-white"} aria-controls="responsive-navbar-nav"/>
                                <Navbar.Collapse id="responsive-navbar-nav ">
                                    <Nav className="me-auto mx-auto menu d-flex justify-content-center">
                                        {
                                            Menu.map((v: any, i: number) => {
                                                return (
                                                    <Link to={v.url} className={"nav-link text-white"}
                                                          key={i}>{v.name}</Link>
                                                )
                                            })
                                        }
                                    </Nav>
                                    <Nav className={"align-items-center"}>
                                        <Link to="./cart" aria-label={"cart"} className={"nav-link position-relative"}>
                                            <BadgeCart/>
                                            <svg width={44} height={44} strokeWidth={1} aria-hidden="true"
                                                 focusable="false" xmlns="http://www.w3.org/2000/svg"
                                                 viewBox="0 0 40 40" fill="none">
                                                <path
                                                    d="m15.75 11.8h-3.16l-.77 11.6a5 5 0 0 0 4.99 5.34h7.38a5 5 0 0 0 4.99-5.33l-.78-11.61zm0 1h-2.22l-.71 10.67a4 4 0 0 0 3.99 4.27h7.38a4 4 0 0 0 4-4.27l-.72-10.67h-2.22v.63a4.75 4.75 0 1 1 -9.5 0zm8.5 0h-7.5v.63a3.75 3.75 0 1 0 7.5 0z"
                                                    fill="currentColor" fillRule="evenodd"></path>
                                            </svg>
                                        </Link>
                                        <Link to={"./user"} aria-label={"my account"} className={"nav-link"}>
                                            <svg width={"20px"} height={"20px"} strokeWidth={1}
                                                 xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false"
                                                 className="icon icon-account" fill="none" viewBox="0 0 18 19">
                                                <path
                                                    d="M6 4.5a3 3 0 116 0 3 3 0 01-6 0zm3-4a4 4 0 100 8 4 4 0 000-8zm5.58 12.15c1.12.82 1.83 2.24 1.91 4.85H1.51c.08-2.6.79-4.03 1.9-4.85C4.66 11.75 6.5 11.5 9 11.5s4.35.26 5.58 1.15zM9 10.5c-2.5 0-4.65.24-6.17 1.35C1.27 12.98.5 14.93.5 18v.5h17V18c0-3.07-.77-5.02-2.33-6.15-1.52-1.1-3.67-1.35-6.17-1.35z"
                                                    fill="currentColor">
                                                </path>
                                            </svg>
                                        </Link>
                                        <Nav.Link href={"#"} aria-label={"search"} className={"nav-link d-none"}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                 viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
                                                 className="feather feather-search">
                                                <circle cx="11" cy="11" r="8"></circle>
                                                <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                                            </svg>
                                        </Nav.Link>
                                    </Nav>
                                </Navbar.Collapse>
                            </Navbar>
                        </Col>
                    </Row>
                </Container>
            </header>
            {
                window.location.pathname == "/" &&
                <Container className={"h-100"}>
                    <Row style={{marginTop:"-50px"}} className={"h-100 justify-content-center align-items-center"}>
                        <Col md={12} className={"text-center text-white"}>
                            <h1 style={{color: "#97ca00"}} className={"text-uppercase fw-bold"}>We think creative</h1>
                            <h3 className={"text-uppercase"}>We create awesome designs for cool people</h3>
                        </Col>
                    </Row>
                </Container>


            }

        </div>
    )
}

export default Header
